import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button,TextField, Paper, Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, CircularProgress } from "@material-ui/core";
import { DeletePopUp, STableLoader, PageLoader, EmptyCollection } from "../../shared_elements";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPostService, globalDeleteService } from '../../../globalServices';
import { checkApiStatus, checkPermission, checkPermissionHierarchy, getLocalStorageInfo } from '../../utils_v2';
import { jetStreamInstance } from "../../../shared/components";
const AddOwners = ({addOwners, onOwnerSelection, onAssignUserOwners, toggleModalFn}) => {
    const [search, setSearch] = useState('')
    return(
        <Dialog
            open={addOwners.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title">
                Add {jetStreamInstance?"Owner/SPV":"Owner"}(s)
                <div className="console-forms-fields">
                    <TextField 
                        placeholder={`Search for ${jetStreamInstance?'Owner/SPV':'Owner'}(s)`}
                        id='search'
                        value={search}
                        fullWidth
                        margin="normal"
                        onChange={(e) => setSearch(e.target.value)}
                        InputLabelProps={{shrink: true}}
                        variant='outlined'
                    />   
                </div>
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ width: '380px' }}>
                    {(() => {
                        let ownertList = search?.trim().length ? addOwners.data.filter(ownerItem => ownerItem.name.toLowerCase().includes(search.toLowerCase())) : addOwners.data;
                        return (
                            <>
                                {ownertList.map((owner, index) =>
                                    <div key={index}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox onChange={(e) => onOwnerSelection(e.target.checked, owner.id)} checked={addOwners.selected.includes(owner.id)} size='small' color="primary" />}
                                            label={owner.name}
                                            labelPlacement="end"
                                        />
                                    </div>
                                )}
                                {!ownertList.length ?
                                    <div style={{ textAlign: 'center' }}>
                                        <EmptyCollection title="No records found" />
                                    </div> : null
                                }
                            </>
                        )
                    })()}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color="primary" size="small" variant="outlined">Cancel</Button>
                {addOwners.selected.length ?
                    <Button onClick={onAssignUserOwners} color="primary" size="small" variant="contained">Save</Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}
const UserOwners = ({params, userInfo}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [addOwners, setAddOwners] = useState({modal:false, selected:[], data:[]})
    const [deleteOwners, setDeleteOwners] = useState({modal:false,  data:null})
    const [userOwners, setUserOwners] = useState([])
    useEffect(() => {
        getUserOwners('skeletonLoader');
    },[]);
    const onOwnerSelection = (flag, id) => {
        if(flag){
            setAddOwners({...addOwners, selected:[...addOwners.selected, id]})
        }else{
            setAddOwners({...addOwners, selected:addOwners.selected.filter(item => item !== id)})
        }
    }
    const getAllOwners = () => {
        setFetching(true);
        globalGetService(`console/owner/`,{allowed_owners:true,user_slug:params.slug})
        .then(response => {
            if(checkApiStatus(response)){
                setAddOwners({modal:true, selected:[],data:response.data.data})    
            }
            setFetching(false);
        })
    }
    const getUserOwners = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) :setLoading(true);
        globalGetService(`console/owner-asset-access-list/user/${params.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                if(Object.keys(response.data.data).length){
                    setUserOwners(response.data.data)
                }else{
                    setUserOwners([])
                }
                
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false) :setLoading(false);
        })
    }
    const onAssignUserOwners = () => {
        setLoading(true)
        globalPostService(`console/owner-asset-access-list/user/${params.slug}/`, {owner_list:addOwners.selected})
        .then(response => {
            if(checkApiStatus(response)){
                setAddOwners({modal:false, selected:[], data:[]});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getUserOwners('pageLoader');
            }else{
                // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    }
    const onDeleteOwners = () => {
        setLoading(true)
        globalDeleteService(`console/owner-asset-access-list/user/${params.slug}/`, {ids:[deleteOwners.data.owner.id]})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteOwners({modal:false, data:null})
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getUserOwners('pageLoader');
            }else{
                // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    }
    const loggedUserId = getLocalStorageInfo()?.user?.id || null;
    return(
        <div>
            {(userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile', 'allowed_owner','U') && checkPermissionHierarchy(userInfo) ?
                <Button disabled={isFetching} style={{marginBottom:'10px'}} onClick={() => getAllOwners()} color="primary" size="small" variant="contained">
                    {isFetching ? <CircularProgress size={20} />:`Add  ${jetStreamInstance?"Owner/SPV":"Owner"}(s)`}
                </Button>:null
            }
            
            <Paper>
                { skeletonLoader ? <STableLoader count={2} />:
                    <>
                        <Table className='mui-table-format margin-bottom40'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    {(userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile', 'allowed_owner','D') && checkPermissionHierarchy(userInfo) ?
                                        <TableCell align="right">Action</TableCell>:null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userOwners.map((item, index) =>
                                    <TableRow>
                                        <TableCell>{item?.owner?.name||''}</TableCell>
                                        {(userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile', 'allowed_owner','D') && checkPermissionHierarchy(userInfo) ?
                                            <TableCell align="right">
                                                <Tooltip title={`Remove ${jetStreamInstance?"Owner/SPV":"Owner"}`} arrow><DeleteOutlineIcon onClick={() => setDeleteOwners({modal:true, data:item})} color="secondary" fontSize="small" /></Tooltip>
                                            </TableCell>:null
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        { !userOwners.length ? <div style={{textAlign:'center'}}><EmptyCollection title='No records found' /></div>:null}
                    </>
                }
            </Paper>
            { addOwners.modal ?
                <AddOwners 
                    addOwners={addOwners}
                    onOwnerSelection={onOwnerSelection}
                    onAssignUserOwners={onAssignUserOwners}
                    toggleModalFn={() => setAddOwners({modal:false, selected:[], data:[]})}
                />:null
            }
            { deleteOwners.modal ?
                <DeletePopUp
                    modal={deleteOwners.modal}
                    title={`Remove ${jetStreamInstance?"Owner/SPV":"Owner"}`}
                    content={`Are you sure, you want to Remove?`}
                    toggleModalFn={() => setDeleteOwners({modal:false, data:null})}
                    deleteRecordFn={onDeleteOwners}
                    confirmText="Remove"
                />:null
            }
            {isLoading ? <PageLoader />:null }
        </div>
    )
}
export default withRouter(UserOwners);