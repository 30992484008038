import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { AssetHeader, AssetList, AssetTab, AddAircraft, AddALP, AddEngine, AddAssetDropdown, VariableRentModal, ImportAsset } from '../components'
import { globalGetService, globalPostService, globalExportService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { downloadFileType, getLocalStorageInfo } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { Pagination, FilterComponent, STableLoader, ExportMenu, PageLoader, EmptyCollection } from '../../shared_elements';
import { Table, TableBody, Paper, Grid, Button } from '@material-ui/core';
import { assetFilterOptions, leaseStatus } from '../';
import config from '../../../config';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
import { castleAirInstance, halcyonInstance } from '../../../shared/components';
const ActiveAssets = ({location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [sortInfo, setSortInfo] = useState({sort:'', sort_by:''});
    const [filter, setFilter] = useState({asset_type:getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )  || halcyonInstance ? 2 : 0});
    const [variableRentInfo, setVariableRentInfo] = useState({modal:false, asset:null})
    const [activeAssets, setActiveAssets] = useState({asset:[], pagination:{}});
    const [addAsset, setAddAsset] = useState({modal:false, asset_type:null})
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [filterAircraftTypes, setFilterAircraftTypes] = useState([]);
    const [engineTypes, setEngineTypes] = useState([]);
    const [filterEngineTypes, setFilterEngineTypes] = useState([]);
    const [lessee, setLessee] = useState([]);
    const [filterLessee, setFilterLessee] = useState([]);
    const [apuList, setApuList] = useState([]);
    const [operators, setOperators] = useState([]);
    const [regions, setRegions] = useState([]);
    const [owners, setOwners] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    const [permissionsData, setPermission] = useState(false);
    const [message, setMessage] = useState({})
    useEffect(() => {
        getActiveAssets({...filter, per_page:50}, 'skeletonLoader');
        getAircraftTypes();
        getEngineTypes();
        getLessee();
        getPlatformConstants();
        getOperatorsList();
        getRegionsList();
        getOwnersPortfolioList();
        getApuList();
        modalOpen();
        checkApiPermission()
    },[]);
    const checkApiPermission =()=>{
        globalGetService(`console/api/user/permission/`).then(response=>{
            setPermission(response.data.data?.permission)
        })
    }
    const modalOpen=()=>{
        if (location.query.apps && location.query.asset_type) {
            setAddAsset({modal:true, asset_type:parseInt(location.query.asset_type)})
        } 
    }
    const getAircraftTypes = () => {
        globalGetService(`console/aircraft-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAircraftTypes(response.data.data.aircraftTypes);
            }
        })
        globalGetService(`console/aircraft-types/`,{distinct:1})
        .then(response => {
            if(checkApiStatus(response)){
                setFilterAircraftTypes(response.data.data.aircraftTypes);
            }
        })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setEngineTypes(response.data.data.engineTypes)
            }
        })
        globalGetService(`console/engine-types/`,{distinct:1})
        .then(response => {
            if(checkApiStatus(response)){
                setFilterEngineTypes(response.data.data.engineTypes)
            }
        })
    }
    const getLessee = () => {
        globalGetService(`console/lessees/`)
        .then(response => {
            if(checkApiStatus(response)){
                setLessee(response.data.data.lessees)
            }
        })
        globalGetService(`console/lessees/?distinct=1`)
        .then(response => {
            if(checkApiStatus(response)){
                setFilterLessee(response.data.data.lessees)
            }
        })
    }
    const getApuList = () => {
        globalGetService(`/console/apu-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setApuList(response.data.data?.apu_types)
            }
        })
    }
    const getOperatorsList = () => {
        globalGetService('console/operators/')
        .then(response => {
            if(checkApiStatus(response)){
                setOperators(response.data.data.operators);
            }
        })
    }
    const getRegionsList = () => {
        globalGetService('console/regions/')
        .then(response => {
            if(checkApiStatus(response)){
                setRegions(response.data.data.region_list);
            }
        })
    }
    const getOwnersPortfolioList = () => {
        globalGetService('console/owner/')
        .then(response => {
            if(checkApiStatus(response)){
                setOwners(response.data.data);
            }
        })
    }
    const getPlatformConstants = () => {
        globalPostService('console/get-constants/',{constant_types:['asset_status', 'asset_sub_status', 'credit_classification', 'engine_position', 'lg_position']})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const getActiveAssets = (query, loaderType) => {
        let queryParams = Object.assign({}, query);
        if(queryParams.asset_type === 0){
            delete queryParams['asset_type']
        }
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`console/list/`, {...queryParams, lite:1,extra_columns:'major_assemblies,lessor_name,model'})
        .then(response => {
            if(checkApiStatus(response)){
                setActiveAssets(response.data.data);
                setMessage(response.data)
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFilter(query);
            }else{

            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }
    const onChangeTab = (event, newValue) => {
        getActiveAssets({per_page:50, asset_type: newValue} , 'pageLoader')
    }
    const createSortHandler = (key) => {
        let sortQuery = {sort:key};
        if(sortInfo.sort === key){
            sortQuery = { ...sortQuery, sort_by:sortInfo.sort_by === 'asc'?'desc':'asc'}
        }else{
            sortQuery = { ...sortQuery, sort_by:'asc'}
        }
        setSortInfo(sortQuery)
        getActiveAssets({...filter, ...sortQuery, page:1, per_page:activeAssets.pagination.per_page},'pageLoader');
    }
    const exportActiveAssets = (extension) => {
        setLoading(true);
        let queryParams = Object.assign({}, filter);
        if(queryParams.asset_type === 0){
            delete queryParams['asset_type']
        }
        globalExportService(`console/list/`, {download:extension, ...queryParams})
        .then(response => {
            setLoading(false);
            if(getLocalStorageInfo().defaultLessor.id===442 && ['xls','xlsx'].includes(extension)){
                enqueueSnackbar("Download link for Report will be sent to your registered email", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              }else if (getLocalStorageInfo().defaultLessor.id===442 && extension == 'pdf') {
                downloadFileType(response, getLocalStorageInfo().defaultLessor.name + '_Assets.', extension)
              }else{
                downloadFileType(response, getLocalStorageInfo().defaultLessor.name + '_Assets.', extension)
              }
              trackActivity('Asset List', {
                page_title: 'Asset Listing',
                event_type: 'Item Exported',
                event_desc: `Exported Assets from Assets List`,
                file_extension: extension
              })
        });
    }
    const onCloseAddAssetModal= ()=>{
        if (location.query.apps && location.query.asset_type) {
            window.location.href = location.query.apps === "records"  ? `${config.domain[location.query.apps]}assets` :`${config.domain[location.query.apps]}assets-listing`;
        }
    }

    const { jacLeaseStatus, defaultLeaseStatus, airAsiaLeaseStatus ,castleLakeStatus, thirdParty} = leaseStatus;
    let filterOptions = Object.assign({}, assetFilterOptions);
    filterOptions = {
        ...filterOptions,
        aircraft_type:(filter.asset_type === 0 || filter.asset_type === 1) && {
            ...filterOptions.aircraft_type,
            options:filterAircraftTypes
        },
      
        engine_type:{
            ...filterOptions.engine_type,
            options:filterEngineTypes
        },
        lessee:{
            ...filterOptions.lessee,
            options:filterLessee
        },
        status:{
            ...filterOptions.status,
            options: getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 ? (jacLeaseStatus.concat(thirdParty)) : castleAirInstance? castleLakeStatus : defaultLeaseStatus
        },
        apu_type:(filter.asset_type === 0 || filter.asset_type === 1 || filter.asset_type === 3) && {
            ...filterOptions.apu_type,
            options :apuList
        },
        sub_status:{
            ...filterOptions.sub_status,
            options:pltConstants.filter(item => item.type === 'asset_sub_status')
        }
    }
    if(getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 ) && filter.asset_type === 2){
        delete filterOptions.registration
    }
    return(
        <section className='fleets-management' style={{margin:'24px 0 0 80px'}}>
            <DeploymentMessage />
            <div>
                { skeletonLoader ? <STableLoader count={10} />:
                    <>  
                        <AssetTab 
                            assetCount={activeAssets.asset_count} 
                            pagination={activeAssets.pagination}
                            filter={filter}
                            onChangeTab={onChangeTab}
                        />
                        {castleAirInstance ?
                            <ul className='list-inline' style={{ textAlign: 'right'}}>
                                <li className='list-inline-item'>
                                    {message.sync_message_progress ?
                                        <p style={{ background: '#FFF8B9', fontSize: '12px', width: 'fit-content', padding: '0px 10px' }}>{message.sync_message_progress}</p> :
                                        <p style={{ background: '#02900C', fontSize: '12px', color: 'white', width: 'fit-content', padding: '0px 10px' }}>{message.sync_message_complete}</p>
                                    }
                                </li>
                            </ul> : null
                        }  
                        <Paper square style={{padding:'5px 10px'}}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item lg={9} md={7} sm={7} >
                                    {(()=>{
                                        let assetFilterObj = Object.assign({},filter)
                                        delete assetFilterObj['asset_type']
                                        return(
                                            <FilterComponent 
                                                filter={assetFilterObj} 
                                                filterMenu={filterOptions}
                                                getResponseBack={(applyFilter) => getActiveAssets({...applyFilter, asset_type:filter.asset_type, page:1, per_page:activeAssets.pagination.per_page}, 'pageLoader')}
                                            />
                                        )
                                    })()}
                                </Grid>
                                <Grid item lg={3} md={5} sm={5}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        <li className='list-inline-item'>
                                            <AddAssetDropdown 
                                                onAddAsset={(type) => setAddAsset({modal:true, asset_type:type})}
                                            />
                                        </li>
                                        {activeAssets?.asset?.length ?
                                            <li className='list-inline-item'>
                                                <ExportMenu 
                                                    exportReportFn={(file) => exportActiveAssets(file.extension)}
                                                    files={[{title:'PDF', extension:'pdf'}, {title:'EXCEL', extension:'xls'}]}
                                                />
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className='major-assemblies-list'>
                            <div style={{height: !activeAssets.asset.length ? 'auto':`${window.innerHeight-256}px`,overflow:'auto'}}>
                                <Table className='mui-table-format' stickyHeader>
                                    <AssetHeader 
                                        fleetType='active'
                                        filter={filter}
                                        sortInfo={sortInfo}
                                        createSortHandler={createSortHandler}
                                    />
                                    <TableBody>
                                        {activeAssets.asset.map((item,index) =>
                                            <AssetList 
                                                fleetType='active'
                                                item={item}
                                                key={index}
                                                filter={filter}
                                                onOpenVariableRent={() => setVariableRentInfo({modal:true, asset:item})}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <Pagination 
                                pagination={activeAssets.pagination}
                                onChangePage={(event, newPage) => getActiveAssets({...filter, page:newPage+1, per_page: activeAssets.pagination.per_page, sort_by:sortInfo.sort_by, sort:sortInfo.sort}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getActiveAssets({...filter, page:1, per_page: event.target.value, sort_by:sortInfo.sort_by, sort:sortInfo.sort}, 'pageLoader')}
                            />
                            {!activeAssets.asset.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                        </Paper>
                    </>
                }
            </div>
            { addAsset.modal && addAsset.asset_type === 1 ?
                <AddAircraft 
                permission={permissionsData}
                    addAsset={addAsset}
                    ownersList={owners}
                    portfolios={[]}
                    lessees={lessee}
                    operators={operators}
                    pltConstants={pltConstants}
                    aircraftTypes={aircraftTypes}
                    toggleModalFn={() => {setAddAsset({modal:false, asset_type:null});onCloseAddAssetModal()}}
                    location={location}
                    getResponseBack={()=>onCloseAddAssetModal()}
                />:null
            }
            { addAsset.modal && [3,4,5].includes(addAsset.asset_type) ?
                <AddALP 
                permission={permissionsData}
                    addAsset={addAsset}
                    ownersList={owners}
                    portfolios={[]}
                    lessees={lessee}
                    operators={operators}
                    pltConstants={pltConstants}
                    aircraftTypes={aircraftTypes}
                    regions={regions}
                    location={location}
                    toggleModalFn={() => {setAddAsset({modal:false, asset_type:null});onCloseAddAssetModal()}}
                    getResponseBack={()=>onCloseAddAssetModal()}
                />:null
            }
            { addAsset.modal && [2,6,8,9].includes(addAsset.asset_type) ?
                <AddEngine 
                permission={permissionsData}
                    addAsset={addAsset}
                    ownersList={owners}
                    portfolios={[]}
                    lessees={lessee}
                    operators={operators}
                    pltConstants={pltConstants}
                    engineTypes={engineTypes}
                    regions={regions}
                    location={location}
                    toggleModalFn={() => {setAddAsset({modal:false, asset_type:null});onCloseAddAssetModal()}}
                    getResponseBack={()=>onCloseAddAssetModal()}
                />:null
            }
            { addAsset.modal && addAsset.asset_type === 7 ?
               <ImportAsset
                    addAsset={addAsset}
                    toggleModalFn={() => {setAddAsset({modal:false, asset_type:null})}}
                    getResponseBack={() => getActiveAssets({...filter, per_page:50}, 'pageLoader')}
               /> : null}

            {variableRentInfo.modal ? 
                <VariableRentModal 
                    variableRentInfo={variableRentInfo}
                    toggleModalFn={() =>setVariableRentInfo({modal:false, asset:null})}
                />:null
            }
            { isLoading ? <PageLoader/>:null}
        </section>
    )
}
export default ActiveAssets;