import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Tooltip, Avatar, IconButton, TextField, FormControl, FormLabel, FormControlLabel, Switch, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { PageLoader } from '../../shared_elements'
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../utils_v2';
import { jetStreamInstance } from '../../../shared/components';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
}));
const EditLessorProfile = ({editProfile, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [lessor, setLessor] = useState(editProfile.data);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setLessor({...lessor,[key]:value})
    }
    const onEditLessorProfile = () => {
        let payload = Object.assign({}, lessor);
        let validationInputs = {
            name: lessor?.name?.trim()?.length ? '':'Please enter Name',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            globalPutService(`console/lessor_tile_update/${lessor.slug}/`, payload)
            .then(response => {
                if(checkApiStatus(response)){
                    toggleModalFn();
                    getResponseBack();
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false);
            })
        }else{
            setError(validationInputs)
        }
    }
    return(
        <Dialog
            open={editProfile.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                Edit {jetStreamInstance?"Lessor/Holdco":"Lessor"} Profile
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField 
                            disabled
                            required
                            id='name'
                            label='Name'
                            value={lessor?.name}
                            fullWidth
                            margin="normal"
                            error={error.name}
                            helperText={error?.name||''}
                            onChange={(e) => onFieldChange('name', e.target.value)}
                            onFocus={() => setError({...error,'name':''})}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField 
                            id='contact_address'
                            label='Contact Address'
                            value={lessor?.contact_address}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('contact_address', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField 
                            id='country'
                            label='Country'
                            value={lessor?.country}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('country', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onEditLessorProfile} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} />:'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const LessorProfile = ({}) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [lessorInfo, setLessorInfo] = useState({})
    const [editProfile, setEditProfile] = useState({modal:false, data:null})
    useEffect(()=> {
        getLessorProfile()
    },[]);
    const getLessorProfile = () => {
        globalGetService(`console/lessor_tile_update/${getLocalStorageInfo().defaultLessor.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setLessorInfo(response.data.data)
            }
        })
    }
    const onChangeAutoInvoice = (value) => {
        setLoading(true)
        globalPutService(`console/auto-invoice-generate/`,{auto_invoice_generate:value})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getLessorProfile();
            }
            setLoading(false)
        })
    }
    const onChangeAssemblyInvoice = (value) => {
        setLoading(true)
        globalPostService(`console/lease-major-assembly-status-update/`,{lease_major_assembly_status:value})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getLessorProfile();
            }
            setLoading(false)
        })
    }
    const onUploadLessorProfile = (files) => {
        if(files.length){
            let formData = new FormData();
            formData.append('logo', files[0]);
            setLoading(true)
            globalPutService(`console/update-lessor/${getLocalStorageInfo().defaultLessor.slug}/`,formData)
            .then(response => {
                if(checkApiStatus(response)){
                    let userInfo = getLocalStorageInfo();
                    userInfo = {
                        ...userInfo,
                        defaultLessor: {
                            ...userInfo.defaultLessor,
                            logo:response.data.data.logo
                        }
                    }
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    window.location.reload();
                }
                setLoading(false)
            })
        }
    }
    return(
        <div className="console-forms-fields" style={{position:'relative'}}>
            {checkPermission('console', 'lessor_configuration','U') ?
                <span  style={{position:'absolute', top:'-3px', right:'0'}} onClick={() => setEditProfile({modal:true,data:lessorInfo})}><EditIcon style={{ cursor: 'pointer' }} color='primary' fontSize='small' /></span>:null
            }
            
            <Grid container spacing={1}>
                <Grid item md={2}>
                    <div style={{textAlign:'center'}}>
                        <span style={{position:'relative'}}>
                            <Avatar src={lessorInfo?.logo || null} alt={''} style={{width:'100px', height:'100px', margin:'0 auto'}} />
                            {checkPermission('console', 'lessor_configuration','U') ?
                                <span style={{position:'absolute', bottom:'0', textAlign:'center', cursor:'pointer'}}>
                                    <input onChange={(e) => onUploadLessorProfile(e.target.files)} accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                                    <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera color='primary' fontSize="small" />
                                        </IconButton>
                                    </label>
                                </span>:null
                            }
                        </span>
                    </div>
                </Grid>
                <Grid item md={10}>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <TextField 
                                disabled
                                id='name'
                                label='Name'
                                value={lessorInfo?.name}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField 
                                disabled
                                id='contact_address'
                                label='Contact Address'
                                value={lessorInfo?.contact_address}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField 
                                disabled
                                id='country'
                                label='Country'
                                value={lessorInfo?.country}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                            />
                        </Grid>
                        {getLocalStorageInfo()?.user?.permission?.contracts ?
                            <Grid item md={12}>
                                <div style={{ background: '#f1f1f1', padding: '10px 15px', marginTop: '5px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item md={6}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Auto-Invoice Generation <Tooltip title='Please note currently auto generation of invoice is only applicable on Rental invoices for assets with Fixed Monthly Lease Rental Rates and Lease Duration Type: Fixed Date' arrow><InfoIcon color='primary' fontSize='small' /></Tooltip></FormLabel>
                                                <FormControlLabel
                                                    control={<Switch color='primary' checked={lessorInfo?.auto_invoice_generate === 1 ? true : false} onChange={(e) => checkPermission('console', 'lessor_configuration', 'U') ? onChangeAutoInvoice(e.target.checked ? 1 : 0) : e.preventDefault()} name="gilad" />}
                                                    label={lessorInfo?.auto_invoice_generate === 1 ? 'Enabled' : 'Disabled'}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item md={6}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Enable Invoice for Fitted Assemblies <Tooltip title='By Default Invoice are enabled for Titled Assemblies' arrow><InfoIcon color='primary' fontSize='small' /></Tooltip></FormLabel>
                                                <FormControlLabel
                                                    control={<Switch color='primary' checked={lessorInfo?.lease_major_assembly_status === 2 ? true : false} onChange={(e) => checkPermission('console', 'lessor_configuration', 'U') ? onChangeAssemblyInvoice(e.target.checked ? 2 : 1) : e.preventDefault()} name="gilad" />}
                                                    label={lessorInfo?.lease_major_assembly_status === 2 ? 'Enabled For Fitted' : 'Enabled For Titled'}
                                                />
                                            </FormControl>
                                        </Grid> */}
                                    </Grid>
                                </div>
                            </Grid> : null}
                    </Grid>
                </Grid>
            </Grid>
            { editProfile.modal ?
                <EditLessorProfile 
                    editProfile={editProfile}
                    toggleModalFn={() => setEditProfile({modal:false, data:null})}
                    getResponseBack={() => getLessorProfile()}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </div>
    )
}
export default LessorProfile;