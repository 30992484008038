import React, { Component, createRef } from 'react';
import { browserHistory } from 'react-router'
import { FeaturesHeader, WhatsSparta, ModulesFeatures, OtherFeatures, AssetLifecycle, Partners, DigitalSuccess, ContactUs, PopupVideo, RequestADemoPopup, AssetDetails } from '../components';
import { Footer } from '../../shared_elements';
import { trackFeatureActivity } from '../../../utils/mixpanel';
import { getLocalStorageInfo } from '../../../utils'
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
import ApplicationsAccess from '../../fleet/pages/ApplicationsAccess';
const requestObj = {
    user_type: 'Lessor',
    email: '',
    name: '',
    interest: [],
    source: null,
    user_company: ''
}
class SpartaSolutions extends Component {
    scrollSec = createRef();
    scrollSecAt = createRef();
    constructor(props) {
        super(props);
        this.state = {
            requestDemo:{modal:false, data:requestObj},
            videoModal: false,
            tabIndex: 0,
            otherTabIndex: 0,
        }
    }
    componentDidMount() {
        trackFeatureActivity('Page Visited', { page_title: 'Solutions' })
        const sourceTab = ['LEASE','AIRWORTHINESS_MANAGEMENT', 'PROJECTS_MANAGEMENT', 'RECORDS_DATAROOM', 'FIN-S', 'COMMVERGE'];
        const appFeatures = ['Managing aviation asset leases and contracts', 'Airworthiness Management', 'Executing due-diligence projects seamlessly', 'Digitising and storing aircraft records on cloud','Aircraft valuations (fin-S)', 'Commercial exposure (commVerge)'];
        let location =browserHistory.getCurrentLocation()
        if (sourceTab.includes(location?.query['demo'])) {
            let tabIndex = location?.query['demo'] == 'FIN-S' || location?.query['demo'] == 'COMMVERGE' ? 0 : sourceTab.indexOf(location?.query['demo'])
            this.setState(prevState => ({
                ...prevState,
                tabIndex: tabIndex,
                otherTabIndex:location?.query['demo'] == 'COMMVERGE' ?1 :0,
                requestDemo: {
                    modal: true, data: {
                        ...requestObj,
                        source:sourceTab[sourceTab.indexOf(location?.query['demo'])],
                        interest: [location?.query['demo'] == 'FIN-S' ? appFeatures[4] : location?.query['demo'] == 'COMMVERGE' ?  appFeatures[5] : appFeatures[tabIndex]]
                    }
                }
            }))
        }
        if(browserHistory.getCurrentLocation().query.requestDemo){
            this.setState(prevState => ({
                ...prevState,
                requestDemo:{modal:true, data:requestObj}
            }))
        }
        this.getScrollableId()
    }

    componentWillMount() {
        if (getLocalStorageInfo() && getLocalStorageInfo().defaultLessor) {
            browserHistory.push('/')
        }
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.location.query.tabIndex && nextProps.location.query.tabIndex !== '' && this.props.location.query.tabIndex !== nextProps.location.query.tabIndex) || nextProps.location.query.target && nextProps.location.query.target !== '' && this.props.location.query.target !== nextProps.location.query.target) {
          this.getScrollableId()
        }
    }

    getScrollableId = () => {
        var location = browserHistory.getCurrentLocation()
        if (location.query.tabIndex && location.query.tabIndex !== '' && location.query.target === 'module-features') {
            this.setState({ tabIndex: parseInt(location.query.tabIndex) }, () => {
                if (location.query.target && location.query.target !== '') {
                    this.scrollTo(location.query.target)
                }
            })
        } else if (location.query.tabIndex && location.query.tabIndex !== '') {
            this.setState({ otherTabIndex: parseInt(location.query.tabIndex) }, () => {
                if (location.query.target && location.query.target !== '') {
                    this.scrollTo(location.query.target)
                }
            })
        } else if (location.query.target && location.query.target !== '') {
            this.scrollTo(location.query.target)
        }
    }

    scrollTo = (id) => {
        window.scroll({ behavior: 'smooth', left: 0, top: document.querySelector(`#${id}`).offsetTop});
    }
    scrollSmoothHandler = () => {
        this.scrollSec.current.scrollIntoView({ behavior: "smooth" });
    };
    scrollSmoothHandlerAt = () => {
        this.scrollSecAt.current.scrollIntoView({ behavior: "smooth" });
    };
    onOpenRequestDemo = (source, interestType) => {
        const sourceParams = ['LEASE MANAGEMENT', 'AIRWORTHINESS MANAGEMENT', 'PROJECTS MANAGEMENT', 'RECORDS DATAROOM', 'Aircraft valuations (fin-S)','Commercial exposure (commVerge)'];
        const sourceTab = ['LEASE', 'AIRWORTHINESS_MANAGEMENT', 'PROJECTS_MANAGEMENT', 'RECORDS_DATAROOM'];
        let currentLocation = browserHistory.getCurrentLocation();
        if (sourceParams.includes(source)) {
            currentLocation = {
                ...currentLocation,
                query: {
                    ...currentLocation?.query,
                    demo: source == 'Aircraft valuations (fin-S)' ? 'FIN-S' : source == "Commercial exposure (commVerge)" ? 'COMMVERGE': `${sourceTab[(this.state.tabIndex)]}`
                }
            }
            browserHistory.push(currentLocation)
        }
        else {
            currentLocation = {
                ...currentLocation,
                query: {}
            }
            browserHistory.push(currentLocation)
        }
        console.log('this.state', this.state.requestDemo);
        this.setState(prevState => ({
            ...prevState,
            requestDemo: {  
                modal:true,
                data:{
                    ...prevState.requestDemo.data,
                    source:source,
                    interest:interestType
                }
            }
        }))
    }
    
    render() {
        const { videoModal, otherTabIndex, requestDemo } = this.state;
        console.log('requestDemo',requestDemo);
        if(getLocalStorageInfo() && getLocalStorageInfo().user !== undefined) {
            return <ApplicationsAccess />
        } else {
            return (
                <section className='sparta-solutions-page' style={{ background: '#fff' }}>
                    {/* <DeploymentMessage/> */}
                    <FeaturesHeader 
                        scrollToDsa={this.scrollSmoothHandler} 
                        scrollToNextSec={() => this.scrollTo('module-features')} 
                    />
                    <WhatsSparta 
                        toggle={() => this.setState({ videoModal: !videoModal })} 
                    />
                    <AssetDetails />
                    {(() => {
                        const appFeatures = ['Managing aviation asset leases and contracts', 'Airworthiness Management', 'Executing due-diligence projects seamlessly', 'Digitising and storing aircraft records on cloud'];
                        const sourceTab = ['LEASE MANAGEMENT','AIRWORTHINESS MANAGEMENT', 'PROJECTS MANAGEMENT', 'RECORDS DATAROOM'];
                        return(
                            <ModulesFeatures 
                                changeTab={(tab) => this.setState({ tabIndex: tab})} 
                                tabIndex={this.state.tabIndex} scrollToDsa={this.scrollSmoothHandler} 
                                scrollToAt={() => this.scrollTo('other-features')} 
                                toggleVideoModal={() => this.setState({ videoModal: !videoModal })} 
                                toggleRequestDemoModal={() => this.onOpenRequestDemo(sourceTab[this.state.tabIndex], [appFeatures[this.state.tabIndex]])}
                            />
                        )
                    })()}
                    <OtherFeatures 
                        otherTabIndex={otherTabIndex} 
                        at={this.scrollSecAt} 
                        changeTab={(tab, application) => this.setState({ otherTabIndex: tab})} 
                        scrollToDsa={this.scrollSmoothHandler} 
                        toggleVideoModal={() => this.setState({ videoModal: !videoModal })} 
                        toggleRequestDemoModal ={() => this.onOpenRequestDemo(otherTabIndex === 0 ? 'Aircraft valuations (fin-S)':'Commercial exposure (commVerge)', [otherTabIndex === 0 ? 'Aircraft valuations (fin-S)':'Commercial exposure (commVerge)'])}
                        scrollToNextSecAl={() => this.scrollTo('asset-lifecycle')} 
                    />
                    <AssetLifecycle
                        toggleVideoModal={() => this.setState({ videoModal: !videoModal })}
                        onFieldChange={(key, value) =>  
                            this.setState(prevState => ({
                                ...prevState,
                                requestDemo: {
                                    ...prevState.requestDemo,
                                    data:{
                                        ...prevState.requestDemo.data,
                                        [key]:value
                                    }
                                }
                            }))
                        }
                        toggleRequestDemoModal={() => this.onOpenRequestDemo('Asset Lifecycle', [])}
                        scrollToNextSec={() => this.scrollTo('partners')} 
                    />
                    <Partners 
                        toggleRequestDemoModal={() => this.onOpenRequestDemo('Jetpack', [])}
                        scrollToNextSec={() => this.scrollTo('digi-success')} 
                    />
                    <DigitalSuccess dsa={this.scrollSec} />
                    <ContactUs 
                        toggleRequestDemoModal={() => this.onOpenRequestDemo('Contact Us', ['Connect me to a consultant'])}
                    />
                    <Footer scrollToModuleFeatures={this.scrollSmoothHandler} />
                    <PopupVideo toggle={() => this.setState({ videoModal: !videoModal })} videoModal={videoModal} />
                    { requestDemo?.modal ?
                        <RequestADemoPopup
                            toggle={() => this.setState({requestDemo:{modal:false, data:requestObj}})}
                            requestDemo={requestDemo}
                        />:null
                    }
                </section>
            )
        }
    }
}
export default SpartaSolutions;
