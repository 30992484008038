import React, { useState } from 'react';
import axios from 'axios'
import { useSnackbar } from 'notistack';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, TextField, FormLabel, FormControl, FormGroup, FormControlLabel, Checkbox, CircularProgress, Radio, RadioGroup } from '@material-ui/core';
import { checkApiStatus } from '../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import config from '../../../config'
import { jetStreamInstance } from '../../../shared/components';
const RequestADemoPopup = ({toggle, requestDemo}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState(requestDemo.data);
  const [error, setError] = useState({});
  const onFieldChange = (key, value) => {
    setFormData({...formData, [key]:value});
  }
  const handleApp = (value, application) => {
    setError({...error,'interest':''})
    if (value) {
      setFormData({...formData, interest: [...formData.interest, application]});
    } else {
      setFormData({...formData, interest: formData.interest.filter(app => app != application)})
    }
  }
  const onRequestDemo = () => {
    let validationInputs = {
      name:formData?.name?.trim()?.length ? '':'Please enter Name',
      email:formData?.email?.trim()?.length ? regexConstants.email.test(formData.email) ? '':'Please enter Valid Email':'Please enter Email',
      interest:formData?.interest?.length?'':'Please choose at least one option'
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      setLoading(true);
      axios.post(`${config.api.networkInterface}console/demo_account_request/`, formData)
      .then(response => {
          if(checkApiStatus(response)){
            toggle();
            enqueueSnackbar('Thanks for reaching us, our sales team will contact to you soon', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
          }
          setLoading(false);
      })
    }else{
      setError(validationInputs)
    }
  }
  return(
    <Dialog onClose={toggle} aria-labelledby="customized-dialog-title" open={requestDemo.modal}>
      <DialogTitle id="customized-dialog-title" onClose={toggle}>Request a Demo</DialogTitle>
      <DialogContent dividers className="request-a-demo-modal">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={3} md={2}>
            <FormLabel component="h4" required>I am a</FormLabel>
          </Grid>
          <Grid item xs={9} md={10}>
            <RadioGroup row onChange={(event) => onFieldChange('user_type', event.target.value)} value={formData.user_type}>
              <FormControlLabel value="Lessor" control={<Radio color='primary' />} label={jetStreamInstance?"Lessor/Holdco":"Lessor"} />
              <FormControlLabel value="Airline" control={<Radio color='primary' />} label="Airline" />
              <FormControlLabel value="Consultant" control={<Radio color='primary' />} label="Consultant" />
              <FormControlLabel value="Others" control={<Radio color='primary' />} label="Others" />
            </RadioGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              id="name"
              label="Name"
              fullWidth
              margin="normal"
              value={formData.name}
              inputProps={{ maxLength: 64 }}
              InputLabelProps={{ shrink: true }}
              error={error?.name}
              helperText={error?.name||''}
              onFocus={() => setError({...error,'name':''})}
              onChange={(e, value) => onFieldChange('name', e.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              id="email"
              label="Email"
              fullWidth
              value={formData.email}
              margin="normal"
              onChange={(e, value) => onFieldChange('email', e.target.value)}
              error={error?.email}
              helperText={error?.email||''}
              onFocus={() => setError({...error,'email':''})}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <div>
              { error?.interest ?<h6 className="error-msg">{error.interest}</h6>:null}
              <FormControl component="fieldset">
                <FormLabel component="legend" className="module-header">Interested In</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name="Lease Management" color="primary" onChange={(e) => handleApp(e.target.checked, 'Managing aviation asset leases and contracts')} checked={formData.interest.includes('Managing aviation asset leases and contracts')} />}
                    label="Managing aviation asset leases and contracts"
                  />
                  <FormControlLabel
                    control={<Checkbox name="Airworthiness Management" color="primary" onChange={(e) => handleApp(e.target.checked, 'Airworthiness Management')} checked={formData.interest.includes('Airworthiness Management')} />}
                    label="Airworthiness Management"
                  />
                  <FormControlLabel
                    control={<Checkbox name="Projects Managemen" color="primary" onChange={(e) => handleApp(e.target.checked, 'Executing due-diligence projects seamlessly')} checked={formData.interest.includes('Executing due-diligence projects seamlessly')} />}
                    label="Executing due-diligence projects seamlessly"
                  />
                  <FormControlLabel
                    control={<Checkbox name="Records Dataroom" color="primary" onChange={(e) => handleApp(e.target.checked, 'Digitising and storing aircraft records on cloud')} checked={formData.interest.includes('Digitising and storing aircraft records on cloud')} />}
                    label="Digitising and storing aircraft records on cloud"
                  />
                </FormGroup>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend" className="module-header">Automation Tools</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name="fin-S" color="primary" onChange={(e) => handleApp(e.target.checked, 'Aircraft valuations (fin-S)')} checked={formData.interest.includes('Aircraft valuations (fin-S)')} />}
                    label="Aircraft valuations (fin-S)"
                  />
                  <FormControlLabel
                    control={<Checkbox name="commVerge" color="primary" onChange={(e) => handleApp(e.target.checked, 'Commercial exposure (commVerge)')} checked={formData.interest.includes('Commercial exposure (commVerge)')} />}
                    label="Commercial exposure (commVerge)"
                  />
                </FormGroup>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend" className="module-header">Looking for something else in Technology?</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name="fin-S" color="primary" onChange={(e) => handleApp(e.target.checked, 'Connect me to a consultant')} checked={formData.interest.includes('Connect me to a consultant')} />}
                    label="Connect me to a consultant"
                  />
                </FormGroup>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" className="btn-color-outlined" autoFocus onClick={toggle} color="primary"> Cancel </Button>
        <Button variant="contained" className="btn-color" autoFocus onClick={onRequestDemo}>
          {isLoading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default RequestADemoPopup;
