import React from "react";
import { FormControlLabel, Grid, TextField, Tooltip, Checkbox } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { GoogleLocationField, LesseeCreate } from "../../shared_elements";
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import InfoIcon from '@material-ui/icons/Info';
import { getLocalStorageInfo, checkPermission } from "../../utils_v2";
import { leaseStatus } from '../../fleet'
import { assetTypeValues } from "../../../constants";
import { jetStreamInstance } from "../../../shared/components";
const EngineModuleForm = ({ formData, mode, onFieldChange, engineTypes, constants, regionOfOperation, owners, lessees, lessors, operators, portfolios, error, onResetError, params, assetInfoData }) => {
    return (
        <div className="console-forms-fields">
            <Grid container spacing={1}>
                <Grid item md={4}>
                    <TextField
                        disabled
                        name='manufacturer'
                        label='Manufacturer'
                        value={formData?.engine_type?.manufacturer?.name ? formData?.engine_type?.manufacturer?.name : formData?.manufacturer?.name || ''}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        InputProps={{ readOnly: true, }}
                    />
                </Grid>
                <Grid item md={4}>
                    <Autocomplete
                        disabled={mode === 'view'}
                        options={engineTypes}
                        getOptionLabel={option => option?.name}
                        name="engine_type"
                        value={formData?.engine_type || null}
                        onChange={(e, value) => { onFieldChange('engine_type', value); onResetError('engine_type') }}
                        renderInput={params =>
                            <TextField
                                required
                                error={error.engine_type ? true : false}
                                helperText={error.engine_type || ''}
                                {...params}
                                label="Engine Type"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />}
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        required
                        disabled={mode === 'view'}
                        name='esn'
                        label='Serial Number'
                        value={formData?.esn || ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => { onFieldChange('esn', e.target.value); onResetError() }}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        error={error.esn}
                        helperText={error.esn || ''}
                    />
                </Grid>
                <Grid item md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            disabled={mode === 'view'}
                            margin="normal"
                            name="manufacturing_date"
                            label={
                                <span >
                                    Date of Manufacture
                                    <Tooltip title='Note:If exact DOM is not available, please update aproximate Date' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                </span>
                            }
                            format={fieldDateFormat}
                            fullWidth
                            disableFuture
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            minDate={moment().subtract(25, 'years')}
                            value={formData?.manufacturing_date || null}
                            onChange={(data, value) => { onFieldChange('manufacturing_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('manufacturing_date') }}
                            inputVariant='outlined'
                            error={error.manufacturing_date ? true : false}
                            helperText={error.manufacturing_date || ''}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            disabled={mode === 'view'}
                            margin="normal"
                            name="purchase_date"
                            label="Date of Purchase"
                            format={fieldDateFormat}
                            fullWidth
                            disableFuture
                            InputLabelProps={{ shrink: true }}
                            minDate={moment().subtract(25, 'years')}
                            value={formData?.purchase_date || ''}
                            onChange={(data, value) => { onFieldChange('purchase_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('purchase_date') }}
                            inputVariant='outlined'
                            error={error.purchase_date ? true : false}
                            helperText={error.purchase_date || ''}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={4}>
                    <Autocomplete
                        disabled={mode === 'view'}
                        options={constants.filter(item => item.type === 'engine_position')}
                        getOptionLabel={option => option?.label}
                        name="position"
                        value={formData?.position || null}
                        onChange={(e, value) => { onFieldChange('position', value); onResetError('position') }}
                        renderInput={params =>
                            <TextField
                                required={formData?.status?.value === 1}
                                error={formData?.status?.value === 1 && error.position ? true : false}
                                helperText={formData?.status?.value === 1 && error.position || ''}
                                {...params}
                                label="Position"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />}
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        required
                        disabled={mode === 'view'}
                        name='country'
                        label='Country'
                        value={formData?.country || ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => { onFieldChange('country', e.target.value); onResetError('country') }}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        error={error.country ? true : false}
                        helperText={error.country || ''}
                    />
                </Grid>
                <Grid item md={4}>
                    {mode === 'view' ?
                        <TextField
                            disabled={true}
                            name='location'
                            label={
                                <span>
                                    Location
                                    <Tooltip title='Input country of operation' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                </span>
                            }
                            value={formData?.location || ''}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            variant='outlined'
                        /> :
                        <GoogleLocationField
                            onFieldChange={onFieldChange}
                            location={formData?.location || ''}
                            disabled={mode === 'view' ? true : false}
                        />
                    }
                </Grid>
                <Grid item md={4}>
                    <Autocomplete
                        disabled={mode === 'view'}
                        options={regionOfOperation}
                        getOptionLabel={option => option?.name}
                        name="region"
                        value={formData?.region || null}
                        onChange={(e, value) => { onFieldChange('region', value); onResetError('region') }}
                        renderInput={params =>
                            <TextField
                                required
                                {...params}
                                label="Region of Operation"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error.region ? true : false}
                                helperText={error.region || ''}
                            />
                        }
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        disabled={mode === 'view'}
                        name='airport_code'
                        label='Airport Code'
                        value={formData?.airport_code || null}
                        fullWidth
                        margin="normal"
                        onChange={(e) => onFieldChange('airport_code', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item md={4}>
                    <Autocomplete
                        disabled={(mode === 'view' || assetInfoData?.status?.value === 5) ? true : checkPermission('technical_specs', 'engine', 'STS') ? false : true}
                        options={getLocalStorageInfo()?.defaultLessor?.id !== 442 ? (constants.filter(item => item?.type === 'asset_status').filter(item => item?.remarks === null).filter(item => item?.value !== 5)) : constants.filter(item => item?.type === 'asset_status').filter(item => item?.remarks === "442")}
                        getOptionLabel={option => option?.label}
                        name="status"
                        value={assetInfoData?.status?.value === 5 ? assetInfoData?.status : formData?.status || null}
                        onChange={(e, value) => { onFieldChange('status', value); onResetError('status') }}
                        renderInput={params =>
                            <TextField
                                required
                                error={error.status ? true : false}
                                helperText={error.status || ''}
                                {...params}
                                label="Lease Status"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        }
                    />
                </Grid>
                {formData?.status?.value === 4 ?
                    <>
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={mode === 'view'}
                                    margin="normal"
                                    name="sold_out_date"
                                    label="Date of Sale"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(assetInfoData?.manufacturing_date)}
                                    value={formData?.sold_out_date || null}
                                    onChange={(data, value) => { onFieldChange('sold_out_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('sold_out_date') }}
                                    inputVariant='outlined'
                                    error={error?.sold_out_date}
                                    helperText={error?.sold_out_date || ''}
                                // onFocus={() => onResetError({ ...error, 'sold_out_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={mode === 'view'}
                                name='tsn_at_sold_date'
                                label='TSN at Sale'
                                value={formData?.tsn_at_sold_date}
                                fullWidth
                                margin="normal"
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn_at_sold_date', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                disabled={mode === 'view'}
                                name='csn_at_sold_date'
                                label='CSN at Sale'
                                value={formData?.csn_at_sold_date}
                                fullWidth
                                margin="normal"
                                onChange={(e) => regexConstants.numberNoSpace.test(e.target.value) ? onFieldChange('csn_at_sold_date', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                variant='outlined'
                            />
                        </Grid>
                    </> : null
                }
                <Grid item md={4}>
                    <Autocomplete
                        disabled={mode === 'view'}
                        options={constants.filter(item => item?.type === 'asset_sub_status')}
                        getOptionLabel={option => option?.label}
                        name="sub_status"
                        value={formData?.sub_status || null}
                        onChange={(e, value) => { onFieldChange('sub_status', value); onResetError('sub_status') }}
                        renderInput={params =>
                            <TextField
                                error={error.sub_status ? true : false}
                                helperText={error.sub_status || ''}
                                required={getLocalStorageInfo()?.defaultLessor?.id === 242}
                                {...params}
                                label="Sub Status"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        }
                    />
                </Grid>
                {formData?.status?.value === 1 ?
                    <>
                        <Grid item md={4}>
                            <LesseeCreate
                                options={lessees}
                                required={true}
                                paramsKey='lessee'
                                optionKey='name'
                                label='Lessee'
                                value={formData?.status?.value === 1 ? formData?.lessee : null || null}
                                onFieldChange={(e, paramsKey, newValue) => onFieldChange('lessee', newValue)}
                                error={error.lessee}
                                resetErrorKey={() => onResetError('lessee')}
                                disabled={formData?.status?.value === 1 ? mode === 'view' : true}
                            />
                            {/* <Autocomplete
                                disabled={formData?.status?.value === 1 ? mode === 'view' : true}
                                options={lessees}
                                getOptionLabel={option => option?.name}
                                name="lessee"
                                value={formData?.status?.value === 1 ? formData?.lessee : null || null}
                                // disableClearable={true}
                                onChange={(e, value) => { onFieldChange('lessee', value); onResetError('lessee') }}
                                renderInput={params =>
                                    <TextField
                                        required={formData?.status?.value === 1}
                                        error={error.lessee ? true : false}
                                        helperText={error.lessee || ''}
                                        {...params}
                                        label="Lessee"
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                    />}
                            /> */}
                        </Grid>

                        <Grid item md={4}>
                            {(() => {
                                let options = [{ label: 'Yes', value: 1 }, { label: 'No', value: 2 }];
                                let optionValue = formData?.same_operator_lessee ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
                                return (
                                    <Autocomplete
                                        disabled={formData?.status?.value === 1 ? mode === 'view' : true}
                                        options={options ? options : []}
                                        getOptionLabel={option => option?.label}
                                        name="same_operator_lessee"
                                        value={formData?.status?.value === 1 ? optionValue : null}
                                        disableClearable={true}
                                        onChange={(e, value) => onFieldChange('same_operator_lessee', value?.value === 1 ? true : false)}
                                        renderInput={params =>
                                            <TextField {...params}
                                                label="Operator is Same as Lessee"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                variant='outlined'
                                            />
                                        }
                                    />
                                )
                            })()}
                        </Grid>
                        <Grid item md={4}>
                            <Autocomplete
                                disabled={formData?.status?.value === 1 ? mode === 'view' || formData?.same_operator_lessee : true}
                                options={operators}
                                getOptionLabel={option => option?.name}
                                name="operator"
                                value={formData?.status?.value === 1 && formData?.same_operator_lessee ? formData.lessee : formData?.operator || null}
                                onChange={(e, value) => { onFieldChange('operator', value); onResetError('operator') }}
                                renderInput={params =>
                                    <TextField
                                        required={formData?.same_operator_lessee === false}
                                        error={formData?.same_operator_lessee === false ? error.operator ? true : false : false}
                                        helperText={formData?.same_operator_lessee === false ? error.operator || '' : ''}
                                        {...params} label="Operator"
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                    />
                                }
                            />
                        </Grid>
                    </> : null
                }
                <Grid item md={4}>
                    <Autocomplete
                        disabled={mode === 'view'}
                        options={lessors}
                        getOptionLabel={option => option?.name}
                        name="lessor_name"
                        value={formData?.lessor_name || null}
                        onChange={(e, value) => { onFieldChange('lessor_name', value); onResetError('lessor_name') }}
                        renderInput={params =>
                            <TextField
                                required
                                error={error.lessor_name ? true : false}
                                helperText={error.lessor_name || ''}
                                {...params}
                                label={jetStreamInstance?"Lessor/Holdco":"Lessor"}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        }
                    />
                </Grid>
                <Grid item md={4}>
                    <Autocomplete
                        disabled={mode === 'view'}
                        options={owners}
                        getOptionLabel={option => option?.name}
                        name="owner"
                        value={formData?.owner || null}
                        onChange={(e, value) => { onFieldChange('owner', value); onResetError('owner') }}
                        renderInput={params =>
                            <TextField
                                required
                                {...params}
                                label={jetStreamInstance?"Owner/SPV":"Owner"}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error.owner ? true : false}
                                helperText={error.owner || ''}
                            />
                        }
                    />
                </Grid>
                <Grid item md={4}>
                    {(() => {
                        let options = [{ value: 1, label: 'Owned' }, { value: 2, label: 'Managed' }];
                        return (
                            <Autocomplete
                                disabled={mode === 'view'}
                                options={options}
                                getOptionLabel={option => option?.label}
                                name="ownership_type"
                                value={formData?.ownership_type || ''}
                                onChange={(e, value) => { onFieldChange('ownership_type', value); onResetError('ownership_type') }}
                                renderInput={params =>
                                    <TextField
                                        required
                                        {...params}
                                        label="Ownership"
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        error={error.ownership_type ? true : false}
                                        helperText={error.ownership_type || ''}
                                    />}
                            />
                        )
                    })()}

                </Grid>
                {formData?.ownership_type?.value === 2 && !jetStreamInstance ?
                    <Grid item md={4}>
                        <Autocomplete
                            disabled={mode === 'view'}
                            options={portfolios}
                            getOptionLabel={option => option?.name}
                            name="owner_portfolio"
                            value={formData?.owner_portfolio || null}
                            onChange={(e, value) => { onFieldChange('owner_portfolio', value); onResetError('owner_portfolio') }}
                            renderInput={params =>
                                <TextField
                                    required={formData?.ownership_type?.value === 2 ? true : false}
                                    {...params}
                                    label="Portfolio"
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    error={error.owner_portfolio ? true : false}
                                    helperText={error.owner_portfolio || ''}
                                />}
                        />
                    </Grid> : null
                }
                <Grid item md={4}>
                    <TextField
                        disabled={mode === 'view'}
                        name='purchase_thrust'
                        label='Purchased Thrust (Lbs)'
                        value={formData?.purchase_thrust || ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('purchase_thrust', e.target.value) : e.preventDefault()}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        disabled={mode === 'view'}
                        name='operating_thrust'
                        label='Operating Thrust (Lbs)'
                        value={formData?.operating_thrust || ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('operating_thrust', e.target.value) : e.preventDefault()}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            disabled={mode === 'view'}
                            margin="normal"
                            name="cta_exp_date"
                            label={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    CTA Date
                                    <Tooltip title='Certificate Of Technical Acceptance' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                </span>
                            }
                            format={fieldDateFormat}
                            fullWidth
                            disableFuture
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            minDate={moment().subtract(25, 'years')}
                            value={formData?.cta_exp_date || null}
                            onChange={(data, value) => { onFieldChange('cta_exp_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('cta_exp_date') }}
                            inputVariant='outlined'
                            error={error.cta_exp_date ? true : false}
                            helperText={error.cta_exp_date || ''}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={4}>
                    <TextField
                        disabled={mode === 'view'}
                        name='next_use'
                        label='Next Use'
                        value={formData?.next_use || ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => onFieldChange('next_use', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            disabled={mode === 'view'}
                            margin="normal"
                            name="date_of_installation"
                            label="Date of Installation"
                            format={fieldDateFormat}
                            fullWidth
                            disableFuture
                            InputLabelProps={{ shrink: true }}
                            minDate={moment().subtract(25, 'years')}
                            value={formData?.date_of_installation || null}
                            onChange={(data, value) => { onFieldChange('date_of_installation', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('date_of_installation') }}
                            inputVariant='outlined'
                            error={error.date_of_installation ? true : false}
                            helperText={error.date_of_installation || ''}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            disabled={mode === 'view'}
                            margin="normal"
                            name="next_delivery_date"
                            label="Next Delivery Date"
                            format={fieldDateFormat}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            minDate={moment().subtract(25, 'years')}
                            value={formData?.next_delivery_date || null}
                            onChange={(data, value) => { onFieldChange('next_delivery_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('next_delivery_date') }}
                            inputVariant='outlined'
                            error={error.next_delivery_date ? true : false}
                            helperText={error.next_delivery_date || ''}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                {
                    getLocalStorageInfo()?.user?.permission?.contracts ?
                        <Grid item md={4} style={{ paddingLeft: '20px', marginTop: '18px' }}>
                            <FormControlLabel control={<Checkbox size="small" disabled={mode === 'view'} color='primary' checked={formData?.match_csn_to_llp ? true : false} onChange={(e) => onFieldChange('match_csn_to_llp', e.target.checked)} name="match_csn_to_llp" />} label={<p style={{ fontSize: '13px' }}>Match LLPs with {assetTypeValues[assetInfoData?.asset_type?.type]?.label} Utilization</p>} />
                        </Grid> : null
                }

                <Grid item md={12}>
                    <TextField
                        disabled={mode === 'view'}
                        name='technical_disclaimer'
                        label='Technical Disclaimer'
                        rows={4}
                        multiline
                        value={formData?.technical_disclaimer || ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => onFieldChange('technical_disclaimer', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </Grid>
            </Grid>
        </div>
    )
}
export default EngineModuleForm;