
import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, FormControlLabel, Checkbox, CircularProgress, InputAdornment } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import { AssetInfo } from "../components";
import { TechSpecsNav } from '../../elements'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { assetTypeValues, backendDateFormat, displayDateFormat, displayDateFormatShort, fieldDateFormat } from "../../../constants";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { getPermissionKey } from "..";
import { regexConstants } from "../../../constants/regEx";
import { downloadFileType } from "../../../utils";
import { Autocomplete } from "@material-ui/lab";
import ShopVisitCard from "../components/ShopVisitCard";
import { trackActivity } from "../../../utils/mixpanel";
const shopVisitobj = {
    name: null,
    shop_visit_start_date: null,
    shop_visit_date: null,
    shop_visit_facility: '',
    tsn: null,
    csn: null,
    cost: null,
    facility_location: '',
    reason: '',
    workscope_details: '',
}
const ShopVisitHd = ({ params }) => {
    return (
        <TableHead>
            <TableRow>
                {params.type === 'aircraft' ?
                    <TableCell>
                        <div style={{ width: '120px' }}>Maintenance Event</div>
                    </TableCell> : null
                }
                <TableCell>
                    <div style={{ width: '157px' }}>Shop Visit Induction Date</div>
                </TableCell>
                <TableCell>
                    <div style={{ width: '167px' }}>Shop Visit Completion Date</div>
                </TableCell>
                <TableCell>
                    <div style={{ width: '150px' }}>Shop Visit Cost (USD)</div>
                </TableCell>

                <TableCell>
                    <div style={{ width: '110px' }}>Shop Visit Facility</div>
                </TableCell>
                <TableCell>
                    <div style={{ width: '120px' }}>Facility Location</div>
                </TableCell>
                <TableCell>
                    <div style={{ width: '110px' }}>TSN at Shop Visit</div>
                </TableCell>
                <TableCell>
                    <div style={{ width: '110px' }}>CSN at Shop Visit</div>
                </TableCell>
                <TableCell>
                    <div style={{ width: '140px' }}>Reason for Shop Visit</div>
                </TableCell>
                <TableCell>
                    <div style={{ width: '182px' }}>Shop Visit Workscope/Details</div>
                </TableCell>
                <TableCell align="right"><div style={{ width: '65px' }}>Action(s)</div></TableCell>
            </TableRow>
        </TableHead>
    )
}
const ShopVisitList = ({ item, onView, onEdit, onDelete, params, setEdit }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    const calculateWidth = (minWidth, maxWidth, content, minCount, maxCount) => {
        let width = 130;
        if (content.length > minCount && content.length <= maxCount) {
            width = Math.max(content.length * 10, minWidth);
        } else if (content.length > maxCount) {
            width = maxWidth;
        }

        return `${width}px`;
    }
    return (
        <TableRow>
            {params.type === 'aircraft' ?
                <TableCell>{item?.name}</TableCell> : null
            }
            <TableCell>
                {moment(item?.shop_visit_start_date).isValid() ? moment(item.shop_visit_start_date).format(displayDateFormatShort) : '--'}
            </TableCell>
            <TableCell>{moment(item?.shop_visit_date).isValid() ? moment(item?.shop_visit_date).format(displayDateFormatShort) : '--'}</TableCell>
            <TableCell>
                <div style={{ width: calculateWidth('100', '142', item?.cost, '15', '') }}>{item?.cost || null}</div>
            </TableCell>
            <TableCell>

                {item?.shop_visit_facility && item?.shop_visit_facility?.length > 30 ? (
                    <div style={{ width: calculateWidth('150', '230', item?.shop_visit_facility, '30', '') }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.shop_visit_facility}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.shop_visit_facility.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: calculateWidth('150', '195', item.shop_visit_facility, '1', '25') }}>{item.shop_visit_facility || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.facility_location && item?.facility_location?.length > 30 ? (
                    <div style={{ width: calculateWidth('120', '200', item?.facility_location, '30', '') }}>
                        {showMore[item?.field] ? (
                            <span> {item?.facility_location}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.facility_location.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: calculateWidth('120', '195', item?.facility_location, '25', '') }}>{item.facility_location || '--'}</div>
                }

            </TableCell>
            <TableCell><div style={{ width: calculateWidth('100', '', item?.tsn, '10', '') }}>{(item?.tsn).toFixed(2) || '--'}</div></TableCell>
            <TableCell><div style={{ width: calculateWidth('100', '', item?.csn, '10', '') }}>{item?.csn || '--'}</div></TableCell>
            <TableCell>
                {item?.removal_reason && item?.removal_reason?.length > 30 ? (
                    <div style={{ width: calculateWidth('150', '230', item?.removal_reason, '30', '') }}>
                        {showMore[item?.field] ? (
                            <span> {item?.removal_reason}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.removal_reason.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: calculateWidth('150', '230', item?.removal_reason, '30', '') }}>{item.removal_reason || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.workscope_details && item?.workscope_details?.length > 30 ? (
                    <div style={{ width: calculateWidth('150', '230', item?.workscope_details, '30', '') }}>
                        {showMore[item?.field] ? (
                            <span> {item?.workscope_details}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.workscope_details.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) : <div style={{ width: calculateWidth('150', '230', item?.workscope_details, '1', '30') }}>{item.workscope_details}</div> || "--"}
            </TableCell>
            <TableCell align="right">
                <ul className="list-inline">
                    {checkPermission('technical_specs', 'shop_visit', 'U') ?
                        <li className="list-inline-item">
                            <Tooltip title='Edit' onClick={() => { setEdit(false); onEdit() }} arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                        </li> : null}
                    {checkPermission('technical_specs', 'shop_visit', 'D') ?
                        <li className="list-inline-item">
                            <Tooltip onClick={() => { setEdit(false); onDelete() }} title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                        </li> : null
                    }
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditShopVisit = ({ addEditShopVisit, toggleModalFn, getResponseBack, params, assetInfoData, event }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [shopVisit, setShopVisit] = useState(addEditShopVisit.data);
    const [error, setError] = useState({})
    const onAddEditShopVisit = () => {
        let validationInputs = {
            workscope_details: shopVisit?.workscope_details?.length > 0 ? '' : 'Please enter Shop Visit WorkScope/Details',
            removal_reason: shopVisit?.removal_reason?.length > 0 ? '' : 'Please enter Reason for Shop Visit',
            cost: shopVisit.cost?.toString()?.trim().length >= 0 ? '' : 'Please enter Shop Visit Cost',
            name: params.type === 'aircraft' ? shopVisit.event_id ? '' : 'Please select Maintenance Event' : '',
            tsn: shopVisit.tsn === null ? 'Please enter TSN at Shop Visit' : shopVisit?.tsn?.toString()?.trim()?.length ? '' : 'Please enter TSN at Shop Visit',
            csn: shopVisit.csn === null ? 'Please enter CSN at Shop Visit' : shopVisit?.csn?.toString()?.trim()?.length ? '' : 'Please enter CSN at Shop Visit',
            shop_visit_date: ['apu', 'lg', 'propeller', 'aircraft'].includes(params?.type) ? (moment(shopVisit?.shop_visit_date).isValid() ? moment(shopVisit.shop_visit_date).isSameOrAfter(assetInfoData?.date_of_manufacture) ? '' : 'Shop Visit Date cant be greater than Date of Manufacture' : 'Please enter Shop Visit Completion Date') : (moment(shopVisit?.shop_visit_date).isValid() ? moment(shopVisit.shop_visit_date).isSameOrAfter(assetInfoData?.manufacturing_date) ? '' : 'Shop Visit Date cant be greater than Date of Manufacture' : 'Please enter Shop Visit Completion Date'),
        }
        if ((['apu', 'lg', 'propeller'].includes(params?.type) ? (assetInfoData?.date_of_manufacture) : (assetInfoData?.manufacturing_date)) && shopVisit?.shop_visit_start_date) {
            validationInputs = {
                ...validationInputs,
                shop_visit_start_date: ['apu', 'lg', 'propeller'].includes(params?.type) ? ((moment(shopVisit?.shop_visit_start_date).isValid()) ? (moment(shopVisit?.shop_visit_start_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day')) ? '' : 'Shop Visit Induction Date should be greater than Date Of Manufacture' : 'Please enter Shop Visit Induction Date') : ((moment(shopVisit?.shop_visit_start_date).isValid()) ? (moment(shopVisit?.shop_visit_start_date).isSameOrAfter(assetInfoData?.manufacturing_date, 'day')) ? '' : 'Shop Visit Induction Date should be greater than Date Of Manufacture' : 'Please enter Shop Visit Induction Date'),
            }
        }
        if (shopVisit?.shop_visit_start_date && shopVisit?.shop_visit_date) {
            validationInputs = {
                ...validationInputs,
                shop_visit_date: (moment(shopVisit?.shop_visit_date).isValid()) ? (moment(shopVisit?.shop_visit_date).isAfter(shopVisit?.shop_visit_start_date)) ? '' : 'Shop Visit Date cant be less than Shop Visit Induction Date' : 'Please enter Shop Visit Completion Date',
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, shopVisit)
            payload = {
                ...payload,
                event_id: shopVisit?.event_id ? shopVisit.event_id : null
            }
            if (shopVisit.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/shop_visit/${shopVisit.id}/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar('Shop Visit Updated Sucessfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            trackActivity('Item Edited', { page_title: 'ShopVisit', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'ShopVisit Edited', event_desc: 'Edited ShopVisit data from Edit ShopVisit form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false)
                    })
            } else {
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/shop_visit/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar('Shop Visit Created Sucessfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            trackActivity('Item added', { page_title: 'ShopVisit', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'ShopVisit Added', event_desc: 'Added ShopVisit data from Add ShopVisit form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false)
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    const onFieldChange = (key, value) => {
        setShopVisit({ ...shopVisit, [key]: value })
    }
    return (
        <Dialog
            open={addEditShopVisit.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {shopVisit?.id ? 'Edit ' : 'Add '} Shop Visit
            </DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Grid container spacing={1}>
                        {params.type === 'aircraft' ?
                            <Grid item md={4}>
                                {(() => {                  
                                  let optionValue = event?.length && shopVisit?.event_id ? event.find((optionItem) => optionItem.id === shopVisit.event_id) : null
                                  return (
                                        <Autocomplete
                                            disabled={shopVisit.id ? true : false}
                                            disableClearable
                                            options={event}
                                            getOptionLabel={(option) => option?.name}
                                            name="event_id"
                                            value={optionValue}
                                            onChange={(e, value) => { onFieldChange('event_id', value?value.id:null ); setError({ ...error, 'name': '' }) }}
                                            renderInput={params =>
                                                <TextField
                                                    required
                                                    {...params}
                                                    label="Select Maintenance Event"
                                                    margin="normal"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    variant='outlined'
                                                    error={error?.name ? true : false}
                                                    helperText={error?.name || ''}
                                                />}
                                        />
                                    )
                                })()}
                            </Grid> : null
                        }
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    name="shop_visit_start_date"
                                    label="Shop Visit Induction Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    value={shopVisit?.shop_visit_start_date || null}
                                    onChange={(data, value) => { onFieldChange('shop_visit_start_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'shop_visit_start_date': '' }) }}
                                    inputVariant='outlined'
                                    minDate={['apu', 'lg', 'propeller', 'aircraft'].includes(params?.type) ? (moment(assetInfoData?.date_of_manufacture)) : (moment(assetInfoData?.manufacturing_date))}
                                    error={error?.shop_visit_start_date ? true : false}
                                    helperText={error?.shop_visit_start_date || ''}
                                // onFocus={() => setError({ ...error, 'shop_visit_start_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    margin="normal"
                                    name="shop_visit_date"
                                    label="Shop Visit Completion Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    value={shopVisit?.shop_visit_date || null}
                                    onChange={(data, value) => { onFieldChange('shop_visit_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'shop_visit_date': '' }) }}
                                    inputVariant='outlined'
                                    minDate={shopVisit.shop_visit_start_date ? moment(shopVisit?.shop_visit_start_date) : ['apu', 'lg', 'propeller', 'aircraft'].includes(params?.type) ? (moment(assetInfoData?.date_of_manufacture)) : (moment(assetInfoData?.manufacturing_date))}
                                    error={error?.shop_visit_date ? true : false}
                                    helperText={error?.shop_visit_date || ''}
                                // onFocus={() => setError({ ...error, 'shop_visit_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                required
                                name='cost'
                                label='Shop Visit Cost(USD)'
                                value={shopVisit?.cost || ''}
                                fullWidth
                                inputProps={{ maxLength: 15 }}
                                margin="normal"
                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('cost', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error.cost ? true : false}
                                onFocus={() => setError({ ...error, 'cost': '' })}
                                helperText={error.cost || ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                name='shop_visit_facility'
                                label='Shop Visit Facility'
                                value={shopVisit?.shop_visit_facility || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 150 }}
                                onChange={(e) => onFieldChange('shop_visit_facility', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                name='facility_location'
                                label='Facility Location'
                                value={shopVisit?.facility_location || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 150 }}
                                onChange={(e) => onFieldChange('facility_location', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                required
                                name='tsn'
                                label='TSN at Shop Visit'
                                value={shopVisit.tsn === null ? '' : shopVisit.tsn}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error.tsn ? true : false}
                                onFocus={() => setError({ ...error, 'tsn': '' })}
                                helperText={error.tsn || ''}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                required
                                name='csn'
                                label='CSN at Shop Visit'
                                value={shopVisit.csn === null ? '' : shopVisit.csn}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => regexConstants.numberNoSpace.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                                error={error?.csn ? true : false}
                                onFocus={() => setError({ ...error, 'csn': '' })}
                                helperText={error?.csn || ''}
                            />
                        </Grid>
                        <Grid item md={8} style={{ paddingLeft: '20px', marginTop: '18px' }}>
                            <FormControlLabel control={<Checkbox size="small" color='primary' checked={shopVisit?.prsv ? true : false} onChange={(e) => onFieldChange('prsv', e.target.checked)} name="prsv" />} label={<p style={{ fontSize: '13px' }}>PRSV Event</p>} />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                required
                                name='removal_reason'
                                label='Reason for Shop Visit'
                                value={shopVisit?.removal_reason || ''}
                                fullWidth
                                margin="normal"
                                multiline
                                onChange={(e) => onFieldChange('removal_reason', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 250 }}
                                variant='outlined'
                                error={error.removal_reason ? true : false}
                                onFocus={() => setError({ ...error, 'removal_reason': '' })}
                                helperText={error.removal_reason || ''}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                required
                                name='workscope_details'
                                label='Shop Visit Workscope/Details'
                                value={shopVisit?.workscope_details || ''}
                                fullWidth
                                margin="normal"
                                multiline
                                onChange={(e) => onFieldChange('workscope_details', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 250 }}
                                variant='outlined'
                                error={error.workscope_details ? true : false}
                                onFocus={() => setError({ ...error, 'workscope_details': '' })}
                                helperText={error.workscope_details || ''}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditShopVisit} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const ShopVisits = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [shopVisitsInfo, setShopVisitsInfo] = useState({ list: [], pagination: {} });
    const [event, setEvent] = useState([]);
    const [addEditShopVisit, setAddEditShopVisit] = useState({ modal: false, data: null });
    const [deleteShopVisit, setDeleteShopVisit] = useState({ modal: false, data: null });
    const [assetInfoData, setAssetInfoData] = useState();
    const [shopvisit, setShopvisit] = useState()
    const [isEdit, setEdit] = useState(false);

    useEffect(() => {
        getShopVisits({}, 'skeletonLoader');
        getAssetInfo('setAssetSkeletonLoader');

        if (['apu', 'lg', 'propeller', 'engine', 'aircraft'].includes(params.type)) {
            shopVistInfo({}, 'skeletonLoader');
        }
    }, []);
    const getDropdownData = () => {
        if (params.type === 'aircraft') {
            getEvent({})
        }
    }
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssetInfoData(response.data.data);
                }
                loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(false) : setLoading(false);
            })
    }
    const getShopVisits = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/shop_visit/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setShopVisitsInfo(response.data.data)
                    if (['apu', 'lg', 'propeller', 'engine','aircraft'].includes(params.type)) {
                        shopVistInfo()
                    }
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    console.log(event)
    const getEvent = () => {
        globalGetService(`console/aircraft-type/${assetInfoData?.aircraft_type?.id}/events/?list=True`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setEvent(response.data.data);             
                }
            })
    }
    const onDeleteShopVisit = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/shop_visit/${deleteShopVisit.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteShopVisit({ modal: false, data: null })
                    enqueueSnackbar('Shop Visit Deleted Sucessfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    getShopVisits({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const shopVistInfo = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/shop_visit_card/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setShopvisit(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onExportShopVist = () => {
        setLoading(true);
        globalExportService(`console/v2/${params.type}/${params.aircraft_slug}/shop_visit/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label} ${assetInfoData.serial_number} shop_visit ${moment().format(fieldDateFormat)}`, 'xlsx')
                trackActivity('Export', { page_title: ' ShopVisit', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'ShopVisit Exported', event_desc: '', file_extension: 'xlsx' });
                // enqueueSnackbar("Incedent-Aog Exported Successully", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                setLoading(false);
            })
    }
    const onFieldChange = (key, value) => {
        setShopvisit({ ...shopvisit, [key]: value })
    }
    return (
        <section className="asset-tech-specs">
            <AssetInfo
                assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()}
            />
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 162}px` }}>
                <TechSpecsNav />
                <div className="tech-specs-content">
                    <div style={{ padding: '10px' }}>
                        {skeletonLoader ? <STableLoader count={9} /> :
                            <>
                                {['apu', 'lg', 'propeller', 'engine', 'aircraft'].includes(params?.type) ?
                                    <div style={{ padding: '3px' }}>
                                        <ShopVisitCard assetInfoData={assetInfoData} onFieldChange={onFieldChange} isEdit={isEdit} setEdit={setEdit} shopVistInfo={shopVistInfo} shopvisit={shopvisit} setShopVisit={setShopvisit} />
                                    </div> : null}
                                <div style={{ padding: '3px' }}>
                                    <Paper square style={{ padding: '5px 10px' }}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item md={9}></Grid>
                                            <Grid item md={3}>
                                                <ul className="list-inline flex-centered" style={{ float: 'right' }}>
                                                    {checkPermission('technical_specs', 'shop_visit', 'C') ?
                                                        <li className="list-inline-item">
                                                            <Button onClick={() => { setEdit(false); getDropdownData(); setAddEditShopVisit({ modal: true, mode: 'add', data: shopVisitobj }) }} color='primary' size='small' variant="contained">Add</Button>
                                                        </li> : null}
                                                    {shopVisitsInfo?.list?.length && checkPermission('technical_specs', 'shop_visit', 'EXP') ?
                                                        <li className="list-inline-item">
                                                            <Button onClick={() => { setEdit(false); onExportShopVist() }} color='primary' size='small' variant="outlined">Export</Button>
                                                        </li> : null}
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <Paper style={{ maxHeight: `${window.innerHeight - 420}px`, overflow: 'auto' }}>
                                        <Table className='mui-table-format' stickyHeader>
                                            <ShopVisitHd params={params} />
                                            <TableBody>
                                                {shopVisitsInfo && shopVisitsInfo?.list?.map((item, index) =>
                                                    <ShopVisitList
                                                        key={index}
                                                        item={item}
                                                        onEdit={() => setAddEditShopVisit({ modal: true, mode: 'edit', data: item })}
                                                        onDelete={() => setDeleteShopVisit({ modal: true, data: item })}
                                                        params={params}
                                                        setEdit={setEdit}
                                                    />
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                    <Paper>
                                        <Pagination
                                            pagination={shopVisitsInfo.pagination}
                                            onChangePage={(event, newPage) => getShopVisits({ ...filter, page: newPage + 1, per_page: shopVisitsInfo.pagination.per_page }, 'pageLoader')}
                                            onChangeRowsPerPage={(event) => getShopVisits({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                        />
                                    </Paper>
                                    <Paper>
                                        {!shopVisitsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                                    </Paper>
                                </div>
                            </>
                        }
                        {addEditShopVisit.modal ?
                            <AddEditShopVisit
                                event={event}
                                addEditShopVisit={addEditShopVisit}
                                toggleModalFn={() => setAddEditShopVisit({ modal: false, data: null })}
                                getResponseBack={() => getShopVisits({}, 'pageLoader')}
                                params={params}
                                assetInfoData={assetInfoData}
                            /> : null
                        }
                        {deleteShopVisit.modal ?
                            <DeletePopUp
                                modal={deleteShopVisit.modal}
                                title='Delete Shop Visit'
                                content={`Are you sure, you want to Delete?`}
                                toggleModalFn={() => setDeleteShopVisit({ modal: false, data: null })}
                                deleteRecordFn={onDeleteShopVisit}
                            /> : null
                        }
                        {isLoading ? <PageLoader /> : null}
                    </div>
                </div>
            </Paper>
        </section>

    )
}
export default ShopVisits;