import React, { useState } from "react";
import { Grid, TextField, Button, Box } from "@material-ui/core";
import { useSnackbar } from 'notistack';
import { Autocomplete } from "@material-ui/lab";
import { PageLoader } from "../../shared_elements";
import enableAssetListing from "../apiService";
import { jetStreamInstance } from "../../../shared/components";

const SelectLessor = ({ userResponse }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [lessorId, setLessorId] = useState(null);
    const [isLoading, setLoading] = useState(false)

    return (
        <>
            <div>
                <form onSubmit={(e)=>{e.preventDefault();enableAssetListing(lessorId.id, userResponse.access,setLoading)}}>
                    <Box className="modal-style">
                        <h4>You are signed in to SPARTA!</h4>
                        <br />
                        <p>Please choose an account to proceed</p>
                        <Autocomplete
                            options={userResponse?.lessor_list || []}
                            getOptionLabel={option => option.name}
                            id="selectlessor"
                            value={lessorId}
                            onChange={(evt, value) => setLessorId(value)}
                            renderInput={params =>
                                <TextField
                                    {...params}
                                    required
                                    id='selectlessor'
                                    name='selectlessor'
                                    label={`Select ${jetStreamInstance?"Lessor/Holdco":"Lessor"} Account to access`}
                                    placeholder="Select Lessor"
                                    variant='outlined'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    margin="normal"
                                />
                            }
                        />
                        <br />

                        <Grid >
                            <Button disabled={!lessorId?.id} fullWidth type='submit' color='primary' size='medium' variant='contained' >{ isLoading ? "Processing..." : "Continue" }</Button>
                            {isLoading ? <PageLoader /> : null}

                        </Grid>
                    </Box>
                </form>
            </div>
            {isLoading ? <PageLoader /> : null}
        </>

    )
}
export default SelectLessor;
