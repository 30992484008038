import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import {TextField, Button, Divider, Paper, Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel, Checkbox, Chip, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, CircularProgress } from "@material-ui/core";
import { DeletePopUp, STableLoader, PageLoader, EmptyCollection } from "../../shared_elements";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPostService, globalDeleteService, globalPutService } from '../../../globalServices';
import { checkApiStatus, checkPermission, checkPermissionHierarchy, getLocalStorageInfo } from '../../utils_v2';
const AddRoles = ({addRoles, onRoleSelection, onAssignUserRoles, toggleModalFn}) => {
    const [search, setSearch] = useState('');
    function groupItemBy(array, property) {
        var hash = {},
        props = property.split('.');
        for (var i = 0; i < array.length; i++) {
            var key = props.reduce(function(acc, prop) {
                return acc && acc[prop];
            }, array[i]);
            if (!hash[key]) hash[key] = [];
            hash[key].push(array[i]);
        }
        return hash;
    }
    const filteredRoles = addRoles.data.filter(role => {
        const roleName = role?.name?.toLowerCase() || '';
        return roleName.includes(search.toLowerCase());
    });

    const grouped = Object.values(groupItemBy(filteredRoles, 'application.id'));
    return(
        <Dialog
            open={addRoles.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title">
                Add Role(s)
                <div className="console-forms-fields">
                    <TextField
                        placeholder="Search for Role(s)"
                        id='search'
                        value={search}
                        fullWidth
                        margin="normal"
                        onChange={(e) => setSearch(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                    />
                </div>

            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ width: '380px' }}>
                    {grouped.map((roles, index) =>
                        <Paper key={index} style={{ marginBottom: '5px' }}>
                            <h5 style={{ fontSize: '16px', padding: '6px', background: '#f1f1f1' }}>{roles[0]?.application?.name || '--'}</h5>
                            {roles.map((role) =>
                                <div key={role.id}>
                                    <div style={{ marginLeft: '10px' }}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox checked={addRoles.selected.map(item => item.id).includes(role.id)} onChange={(e) => onRoleSelection(e.target.checked, role)} size='small' color="primary" />}
                                            label={role?.name || ''}
                                            labelPlacement="end"
                                        />
                                    </div>
                                    <Divider />
                                </div>
                            )}
                        </Paper>
                    )}
                    {grouped.length === 0 && (
                        <div style={{ textAlign: 'center' }}>
                            <EmptyCollection title="No records found" />
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color="primary" size="small" variant="outlined">Cancel</Button>
                {addRoles.selected.length ?
                    <Button onClick={onAssignUserRoles} color="primary" size="small" variant="contained">Save</Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}
const UserRoles = ({params, userInfo}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [addRoles, setAddRoles] = useState({modal:false, selected:[], data:[]})
    const [deleteRoles, setDeleteRoles] = useState({modal:false, data:null})
    const [userRoles, setUserRoles] = useState([])
    useEffect(() => {
        getUserRoles('skeletonLoader');
    },[]);
    const onRoleSelection = (flag, role) => {
        if(flag){
            if(role?.application?.id == 8.1){
                setAddRoles({...addRoles, selected:[...addRoles.selected, role]})
            }
            else{
            if(addRoles.selected.find(item => item.application.id === role.application.id)){
                let arr = addRoles.selected.filter(item => item.application.id !== role.application.id);
                setAddRoles({...addRoles, selected:[...arr, role]})
            }else{
                if(role?.application?.id ==15){
                    setAddRoles({...addRoles, selected:[role]})
                }
                else{
                    if(addRoles?.selected?.filter(item => item?.application?.id ==15)?.length){
                        setAddRoles({...addRoles, selected:[...addRoles?.selected?.filter(item => item?.application?.id !==15), role]})
                    }
                    else{
                        setAddRoles({...addRoles, selected:[...addRoles.selected, role]})
                    }
                }
            }
            }
        }else{
            setAddRoles({...addRoles, selected:addRoles.selected.filter(item => item.id !== role.id)})
        }
    }
    const getAllRoles = () => {
        setFetching(true);
        globalGetService(`console/sparta-group-list/user/${params.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAddRoles({modal:true, selected:userRoles, data:response.data.data})
            }
            setFetching(false);
        })
    }
    const getUserRoles = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) :setLoading(true);
        globalGetService(`console/roles-list/user/${params.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setUserRoles(response.data.data)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false) :setLoading(false);
        })
    }
    const onAssignUserRoles = () => {
        let checkConsole = addRoles.selected?.filter(item => [1,15].includes(item?.application?.id))?.length 
        if(checkConsole){
        setLoading(true)
        globalPutService(`console/sparta-group-list/user/${params.slug}/`, {groups_list:addRoles.selected.map(item => item.id)})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setAddRoles({modal:false, selected:[], data:[]})
                getUserRoles();
            }else{
                // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    } else{
        enqueueSnackbar(' Atleast one role from Console is required or select Console view as default Console role.', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
    }
    }
    const onDeleteRoles = () => {
        let consoleCheck = [1,15].includes(deleteRoles.data?.application?.id) && userRoles?.filter(item => [1,15].includes(item?.application?.id))?.length == 1
        if(consoleCheck){
            enqueueSnackbar(' Atleast one role from Console is required, click on Add Role(s) to select alternate console role.', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
        }
        else{
            setLoading(true)
            globalDeleteService(`console/sparta-group-list/user/${params.slug}/`, {sparta_group_list:[deleteRoles.data.id]})
            .then(response => {
                if(checkApiStatus(response)){
                    setDeleteRoles({modal:false, data:null})
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    getUserRoles();
                }else{
                    // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false)
            })
        }
    }
    const loggedUserId = getLocalStorageInfo()?.user?.id || null;
    return(
        <div>
            { (userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile','roles','U') && checkPermissionHierarchy(userInfo) ?
                <Button disabled={isFetching} style={{marginBottom:'10px'}} onClick={() => getAllRoles()} color="primary" size="small" variant="contained">
                    {isFetching ? <CircularProgress size={24} />:'Add Role(s)'}
                </Button>:null
            }
            
            <Paper>
                { skeletonLoader ? <STableLoader count={3}/>:
                    <>
                        <Table className='mui-table-format margin-bottom40'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Role Name</TableCell>
                                    <TableCell>Application</TableCell>
                                    {(userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile','roles','D') && checkPermissionHierarchy(userInfo) ?
                                        <TableCell align="right">Action</TableCell>:null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { userRoles.map((role, index) => 
                                    <TableRow>
                                        <TableCell>{role?.name||'--'}</TableCell>
                                        <TableCell>{role?.application?.name||'--'}</TableCell>
                                        {(userInfo && Object.keys(userInfo).length && loggedUserId !== userInfo.id) && checkPermission('user_profile','roles','D') && checkPermissionHierarchy(userInfo) ?
                                            <TableCell align="right">
                                                <Tooltip title='Remove Role' arrow><DeleteOutlineIcon onClick={() => setDeleteRoles({modal:true, data:role})} size='small' color='secondary' /></Tooltip>
                                            </TableCell>:null
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {!userRoles.length ? <div style={{textAlign:'center'}}><EmptyCollection title='No records found'/></div>:null}
                    </>
                }
            </Paper>
            { addRoles.modal ?
                <AddRoles 
                    addRoles={addRoles}
                    onRoleSelection={onRoleSelection}
                    onAssignUserRoles={onAssignUserRoles}
                    toggleModalFn={() =>setAddRoles({modal:false, selected:[], data:[]})}
                />:null
            }   
            { deleteRoles.modal ?
                <DeletePopUp
                    modal={deleteRoles.modal}
                    title='Remove Role'
                    content={`Are you sure, you want to Remove?`}
                    toggleModalFn={() => setDeleteRoles({modal:false, data:null})}
                    deleteRecordFn={onDeleteRoles}
                    confirmText="Remove"
                />:null
            }
            {isLoading ? <PageLoader />:null}
        </div>
    )
}
export default UserRoles;