import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Paper, TextField } from "@material-ui/core";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { withRouter } from 'react-router';
import { checkApiStatus } from '../../utils_v2';
import { ExportMenu, PageLoader } from '../../shared_elements';
import { globalGetService } from '../../../globalServices';
import { checkPermission } from '../../../console_management/utils_v2';

const EngineSwapReport = ({ownerList}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const exportAssetLogReport = (extension) => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            start_date: start_date ? '' : 'Please enter From Date',
            end_date: end_date ? '' : 'Please enter To Date',
        }
        if(start_date == 'Invalid date'){
            validationInputs = {
                ...validationInputs,
                start_date: 'Please enter From Date'
            }
        }
        if(end_date == 'Invalid date'){
            validationInputs = {
                ...validationInputs,
                end_date: 'Please enter To Date'
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            globalGetService(`console/engine-swap-report/?startDate=${moment(start_date).format(backendDateFormat)}&endDate=${moment(end_date).format(backendDateFormat)}`)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    setError({})
                }else{
                   // enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            });
        }else{
            setError(validationInputs)
        }
    }
    const onResetErrorKey = (keyParam) => {
        setError({...error, [keyParam]:''})
    }
    return(
        <Paper square>
            <Grid container spacing={0} alignItems='center'>
                <Grid item md={12}>
                    <div className='custom-rpt-card'>
                        <div className='rpt-card-header'>
                            <Grid container spacing={1}>
                                <Grid item md={9}>
                                    <h4>Engine Swap Report</h4>
                                    <p>Report to capture the engine swapping between Lessor/Holdco</p>
                                </Grid>
                                <Grid item md={3}>
                                {checkPermission('reports','custom_reports','EXP') ? <ExportMenu 
                                        exportReportFn={(file) => exportAssetLogReport(file.extension)}
                                        files={[{title:'EXCEL', extension:'xls'}]}
                                    /> : null }
                                </Grid>
                            </Grid>
                        </div>
                        <div className='rpt-card-body'>
                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            margin="normal"
                                            id="start_date"
                                            label="From Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            maxDate={moment()}
                                            value={start_date}
                                            error={error.start_date ? true : false}
                                            helperText={error.start_date ? error.start_date : ''}
                                            onChange={(data) => {setStartDate(('start_date', moment(data).format(backendDateFormat)));onResetErrorKey('start_date')}}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            margin="normal"
                                            id="end_date"
                                            label="To Date"
                                            format={fieldDateFormat}
                                            disabled={!start_date ?  true : false}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment(start_date)}
                                            maxDate={moment(start_date).diff(moment(), 'month') === 0 ? moment(new Date()) :moment(start_date).add(300, 'M')}
                                            value={end_date}
                                            error={error.end_date ? true : false}
                                            helperText={error.end_date ? error.end_date : ''}
                                            onChange={(data) => {setEndDate(('end_date', moment(data).format(backendDateFormat)));onResetErrorKey('end_date')}}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            { isLoading ? <PageLoader/>:null}
        </Paper>
    )
}
export default withRouter(EngineSwapReport);